<template>
  <list-container>
    <AuctionsList type="need-invoice-checking" :create="true" />
  </list-container>
</template>

<script>
import AuctionsList from "@/components/Auctions/AuctionsList"
import ListContainer from "@/components/Auctions/ListContainer"

export default {
  name: "NeedInvoiceChecking",
  components: {ListContainer, AuctionsList}
}
</script>